import Masonry from 'masonry-layout';

let msnry;
let updateScheduled = false;

function initializeMasonry() {
    msnry = new Masonry(document.querySelector('#portfolio'), {
        itemSelector: '.col-md-4',
        percentPosition: true,
        transitionDuration: '0.4s'
    });
}

function updateMasonry() {
    if (!updateScheduled) {
        updateScheduled = true;
        requestAnimationFrame(() => {
            msnry.layout(); 
            updateScheduled = false;
        });
    }
}

window.addEventListener("load", function() {
    initializeMasonry(); // Initialise Masonry lors du chargement de la page
    // Masonry doit être initialisé avant d'écouter les événements lazyloaded
    document.addEventListener("lazyloaded", updateMasonry);
});






