import BigPicture from 'bigpicture';

document.querySelectorAll("[data-bp]").forEach((function(e) {
    e.addEventListener("click", openGallery)
}
));

function openGallery(e) {      
    e.preventDefault();        
    BigPicture({
        el: e.target,
        imgSrc: e.target.dataset.bp,
        noLoader: true
    })                      
}                              