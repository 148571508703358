/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import './styles/app.scss';
import '@fortawesome/fontawesome-free/css/all.min.css'
import '../node_modules/jarallax/dist/jarallax'
import '../node_modules/aos/dist/aos'
import '../node_modules/isotope-layout/dist/isotope.pkgd'
import 'lazysizes';
import * as bootstrap from 'bootstrap'
import './js/scripts'

import '../node_modules/aos/dist/aos'
import './js/bigpicture'
import './js/masonry'
// start the Stimulus application
import './bootstrap';

